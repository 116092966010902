<template>
  <InvitePage :title="title" type="form">
    <InlineLoader v-if="isLoading" />

    <div v-else>
      <Message v-if="inviteNotFound" type="secondary" :message="$t('invite.noInviteFound')" />
      <Message v-else-if="alreadyAccepted" type="secondary" :message="$t('invite.alreadyAccepted')" />

      <form v-else @submit.prevent="join">
        <Paragraph
          v-if="invite !== null"
          :text="$t('invite.huntAreaInviteMessage', { name: invite.createdBy, area: invite.huntArea })">
        </Paragraph>

        <div v-if="invite">
          <Alert v-if="hasError" type="danger" :message="errorMessage" />

          <div v-if="invite.acceptedAt === null">
            <FormField :label="$t('general.email')">
              <EmailInputField v-model="invite.email" disabled />
            </FormField>

            <Divider />

            <FormField :label="$t('auth.firstName')">
              <TextInputField
                v-model="user.firstName"
                :valid="validation.firstName"
                @input="onFirstNameChanged">
              </TextInputField>
            </FormField>

            <FormField :label="$t('auth.lastName')">
              <TextInputField
                v-model="user.lastName"
                :valid="validation.lastName"
                @input="onLastNameChanged">
              </TextInputField>
            </FormField>

            <FormField :label="$t('general.password')">
              <PasswordInputField
                v-model="user.password"
                :invalid="validation.password"
                @input="onPasswordChanged">
              </PasswordInputField>
            </FormField>

            <FormField :label="$t('auth.repeatPassword')">
              <PasswordInputField
                v-model="user.repassword"
                :invalid="validation.repassword"
                @input="onPasswordChanged">
              </PasswordInputField>

              <HelpText
                v-if="passwordMismatch"
                type="danger"
                :text="$t('invite.passwordsNotMatching')">
              </HelpText>
            </FormField>

            <Button
              type="secondary"
              :loading="isSubmitting"
              :title="$t('invite.joinArea')"
              fullwidth
              @click="join">
            </Button>
          </div>
        </div>
      </form>
    </div>
  </InvitePage>
</template>

<script>
import { mapGetters } from 'vuex'

import InvitePage from './InvitePage.vue'

import {
  STATUS_LOADING,
  STATUS_SUBMITTING,
  STATUS_ALREADY_ANSWERED,
  STATUS_UNKNOWN_ERROR,
  STATUS_NOT_FOUND,
  STATUS_EMAIL_EXISTS
} from '../store/inviteStatus'

export default {
  components: {
    InvitePage
  },

  data () {
    return {
      token: this.$route.params.token,

      user: {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        repassword: ''
      },

      validation: {
        firstName: null,
        lastName: null,
        password: false,
        repassword: false
      }
    }
  },

  computed: {
    ...mapGetters({
      invite: 'invites/huntarea/getInvite',
      status: 'invites/huntarea/getStatus'
    }),

    title () {
      return this.invite !== null ? this.$i18n.t('invite.title', { name: this.invite.huntArea }) : ''
    },

    isLoading () {
      return this.status === STATUS_LOADING
    },

    isSubmitting () {
      return this.status === STATUS_SUBMITTING
    },

    passwordMismatch () {
      return this.user.password !== this.user.repassword
    },

    hasError () {
      return this.status === STATUS_UNKNOWN_ERROR || this.status === STATUS_EMAIL_EXISTS
    },

    inviteNotFound () {
      return this.status === STATUS_NOT_FOUND
    },

    alreadyAccepted () {
      return this.status === STATUS_ALREADY_ANSWERED
    },

    errorMessage () {
      const errors = {}

      errors[STATUS_UNKNOWN_ERROR] = this.$i18n.t('invite.unknownErrorMessage')
      errors[STATUS_EMAIL_EXISTS] = this.$i18n.t('auth.join.emailOccupied')

      return errors[this.status] !== undefined ? errors[this.status] : ''
    }
  },

  created () {
    this.$store.dispatch('invites/huntarea/readByToken', this.$route.params.token)
  },

  methods: {
    validate () {
      this.validation.firstName = this.user.firstName.length === 0 ? 'invalid' : null
      this.validation.lastName = this.user.lastName.length === 0 ? 'invalid' : null
      this.validation.password = this.user.password.length === 0
      this.validation.repassword = this.user.repassword.length === 0

      return this.validation.firstName === null && this.validation.lastName === null && !this.validation.password && !this.validation.repassword && !this.passwordMismatch
    },

    onFirstNameChanged () {
      this.validation.firstName = null
    },

    onLastNameChanged () {
      this.validation.lastName = null
    },

    onPasswordChanged () {
      this.validation.password = false
      this.validation.repassword = false
    },

    async join () {
      if (this.validate()) {
        this.user.email = this.invite.email

        try {
          await this.$store.dispatch('invites/huntarea/acceptInvite', this.user)

          await this.$store.dispatch('auth/signIn', {
            email: this.user.email,
            password: this.user.password
          })

          this.$router.push('/')
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
}
</script>
